<template>
  <div class="py-10 h-screen w-screen">
    <div class="flex justify-center">
      <img class="h-1/3 w-1/3 my-5" src="@/assets/images/server-down.gif" />
    </div>
    <div class="text-center">
      <h1 class="mb-2 text-lg font-semibold">Server is down</h1>
      <p class="mt-2 text-sm">Server is down, please contact our services for more information!</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'server-down',
  mounted() {
    this.checking()
  },
  methods: {
    checking() {
      this.$store.dispatch('common/CHECK_SERVER').then(() => this.$router.push('/pages/login'))
    }
  }
}
</script>
