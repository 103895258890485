var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-10 h-screen w-screen"
  }, [_c('div', {
    staticClass: "flex justify-center"
  }, [_c('img', {
    staticClass: "h-1/3 w-1/3 my-5",
    attrs: {
      "src": require("@/assets/images/server-down.gif")
    }
  })]), _c('div', {
    staticClass: "text-center"
  }, [_c('h1', {
    staticClass: "mb-2 text-lg font-semibold"
  }, [_vm._v("Server is down")]), _c('p', {
    staticClass: "mt-2 text-sm"
  }, [_vm._v("Server is down, please contact our services for more information!")])])]);
}]

export { render, staticRenderFns }